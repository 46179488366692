<template>
<g>
  <svg:style>
    .cls-2,.cls-44{stroke:#aeaeae;stroke-width:0.25px;}
    .cls-2{fill:url(#inox-gradient);}
    .cls-44{fill:url(#glass-pattern);}
  </svg:style>

  <linearGradient id="handle-gradient" x1="11.33" y1="150.96" x2="17.01" y2="150.96" gradientUnits="userSpaceOnUse">
    <stop offset="0.01" stop-color="#babbbc"/>
    <stop offset="0.29" stop-color="#989a9e"/>
    <stop offset="0.65" stop-color="#bdbdc1"/>
    <stop offset="0.87" stop-color="#9d9fa2"/>
    <stop offset="1" stop-color="#a6a8aa"/>
    <stop offset="1" stop-color="#c2c5c9"/>
  </linearGradient>
  <linearGradient
    id="inox-gradient"
    :x1="doorLeftWidth1 + 69.91"
    :y1="doorTopHeight1 + 275.05"
    :x2="doorLeftWidth1 + 69.91"
    :y2="doorTopHeight1"
    gradientUnits="userSpaceOnUse">
    <stop offset="0.01" stop-color="#babbbc"/>
    <stop offset="0.29" stop-color="#989a9e"/>
    <stop offset="0.65" stop-color="#bdbdc1"/>
    <stop offset="0.87" stop-color="#9d9fa2"/>
    <stop offset="1" stop-color="#a6a8aa"/>
    <stop offset="1" stop-color="#c2c5c9"/>
  </linearGradient>
  <linearGradient id="glass-gradient"
                  :x1="doorLeftWidth1 + 70.93"
                  :y1="doorTopHeight1 + 241.18"
                  :x2="doorLeftWidth1 + 70.93"
                  :y2="doorTopHeight1 + 59.96"
                  gradientUnits="userSpaceOnUse">
    <stop offset="0" stop-color="#f4f4f4"/>
    <stop offset="0.5" stop-color="#dee8e8"/>
    <stop offset="1" stop-color="#d1cece"/>
  </linearGradient>

  <g id="V33">
    <rect
      id="inox"
      v-if="showInox"
      class="cls-2"
      :x="doorLeftWidth1 + 63.63"
      :y="inoxY"
      width="12.56"
      :height="inoxH" />
    <polyline
      id="glass"
      class="cls-44"
      :points="`
      ${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 239.31}
      ${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 67.64}
      ${doorLeftWidth1 + 63.63} ${doorTopHeight1 + 67.64}
      ${doorLeftWidth1 + 63.63} ${doorTopHeight1 + 239.31}
      ${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 239.31}`"/>
  </g>
</g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
    'laminationId',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
    }
  },
  computed: {
    inoxY() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    inoxH() {
      return this.showBg ?
        this.doorHeight - this.inoxOffset * 2 :
        this.doorHeight - this.leafOffset * 2
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
